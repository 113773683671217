/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "The Influence of Architects and Fashion Designers on Yacht Design"), "\n", React.createElement(_components.h2, null, "Introduction"), "\n", React.createElement(_components.p, null, "According to an article from Robb Report, architects and fashion designers have significantly impacted the world of yacht design. Their innovative ideas and unique perspectives have led to the creation of extraordinary yachts, changing the industry's landscape and pushing the boundaries of what is possible on the high seas."), "\n", React.createElement(_components.h2, null, "Design Visionaries"), "\n", React.createElement(_components.p, null, "One standout designer mentioned in the article is naval architect Espen Øino, known for conceiving some of the largest and most luxurious yachts globally. Based in Monaco, Øino's designs blend elegance, luxury, and functionality seamlessly."), "\n", React.createElement(_components.p, null, "Alberto Mancini, another prominent designer, is celebrated for his sleek and modern yacht designs. His collaborations with various yacht builders have resulted in vessels that marry style with high performance."), "\n", React.createElement(_components.h2, null, "Industry Impact"), "\n", React.createElement(_components.p, null, "The article also sheds light on the Azimut-Benetti Group, the world's largest yacht builder. According to Giovanna Vitelli, the group's chair, architects and fashion designers entering the yacht design realm have not only achieved remarkable success but have also amassed significant wealth in the industry."), "\n", React.createElement(_components.p, null, "To delve deeper into the influence of architects and fashion designers on yacht design, you can access the full article on Robb Report's website: ", React.createElement(_components.a, {
    href: "https://robbreport.com/motors/marine/yachts-designed-by-architects-fashion-designers-1235541422/"
  }, "Architects and Fashion Designers Are Penning Yachts, and It's Changing How They're Made"), "."), "\n", React.createElement(_components.h2, null, "References"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://robbreport.com/motors/marine/yachts-designed-by-architects-fashion-designers-1235541422/"
  }, "Architects and Fashion Designers Are Penning Yachts, and It's Changing How They're Made - Robb Report")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
